import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
  }

  disconnect() {
  }

  remove(event) {
    this.element.remove()
    event.preventDefault();
    return false;
  }
}
