import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Add submit handler when controller connects
    this.element.addEventListener('submit', this.handleSubmit.bind(this))
  }

  async handleSubmit(e) {
    e.preventDefault()

    try {
      // Show loading state if there's a submit button
      const submitButton = this.element.querySelector('[type="submit"]')
      if (submitButton) {
        console.log('submit button:', submitButton)
        submitButton.disabled = true
      }

      // Get reCAPTCHA token
      await new Promise((resolve) => {
        grecaptcha.enterprise.ready(async () => {
          const token = await grecaptcha.enterprise.execute('6LeOoI0qAAAAACjorT3YXf2ZzPnTB4wpbOvD82Vd', {
            action: this.element.dataset.recaptchaAction || 'LOGIN'
          })

          console.log('reCAPTCHA token:', token)

          // Add token to form
          const tokenInput = document.createElement('input')
          tokenInput.type = 'hidden'
          tokenInput.name = 'g_recaptcha_token'
          tokenInput.value = token
          this.element.appendChild(tokenInput)

          resolve()
        })
      })

      // Submit the form
      this.element.submit()

    } catch (error) {
      console.error('reCAPTCHA error:', error)

      // Re-enable submit button if there was an error
      const submitButton = this.element.querySelector('button[type="submit"]')
      if (submitButton) {
        submitButton.disabled = false
        submitButton.classList.remove('loading')
      }
    }
  }
}
