import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
  }

  static targets = [
    "objectivesContainer",
    "template"
  ]

  connect() {
  }

  disconnect() {
  }

  add(event) {
    console.log('add', this.objectivesContainerTarget, this.templateTarget.innerHTML);
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
    this.objectivesContainerTarget.insertAdjacentHTML('beforeend', content);
    const newElement = this.objectivesContainerTarget.lastElementChild;
    const focusableElement = newElement.querySelector('input, textarea, select');
    if (focusableElement) {
      focusableElement.focus();
    }
    event.preventDefault();
    return false;
  }
}
