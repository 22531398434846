import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from '@rails/activestorage'
import { post } from '@rails/request.js'

class Upload {
  constructor(file, endpoint) {
    this.directUpload = new DirectUpload(
      file,
      '/rails/active_storage/direct_uploads',
      this
    )
    this.endpoint = endpoint
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', (event) =>
      this.updateProgress(event)
    )
  }

  updateProgress(event) {
    const percentage = (event.loaded / event.total) * 100
    console.log(percentage)
    const progress = document.querySelector(`#upload_${this.directUpload.id} .file-percent`);
    progress.textContent = `${Math.round(percentage)}%`;
    // progress.style.transform = `translateX(-${100 - percentage}%)`;
  }

  insertUpload() {
    // remove #empty_paperwork_events
    const emptyUploads = document.querySelector('#empty_upload_lines')
    if (emptyUploads) {
      emptyUploads.remove()
    }

    const fileUpload = document.createElement('div')
    fileUpload.id = `upload_${this.directUpload.id}`
    fileUpload.className = 'flex items-center gap-1 mb-2 px-3 py-1 text-sm rounded-full bg-black/5'

    // <span class="file-percent opacity-50">55%</span>
    // <span class="truncate file-name">Uploading file.pdf...</span>

    const fileName = document.createElement('span')
    fileName.className = 'truncate file-name'
    fileName.textContent = `Uploading ${this.directUpload.file.name}...`
    fileUpload.appendChild(fileName)

    const filePercent = document.createElement('span')
    filePercent.className = 'file-percent opacity-50 text-xs'
    filePercent.textContent = '0%'
    fileUpload.appendChild(filePercent)

    // fileUpload.textContent = this.directUpload.file.name
    //
    // const progressWrapper = document.createElement('div')
    // progressWrapper.className =
    //   'relative h-4 overflow-hidden rounded-full bg-secondary w-[100%]'
    // fileUpload.appendChild(progressWrapper)
    //
    // const progressBar = document.createElement('div')
    // progressBar.className = 'progress h-full w-full flex-1 bg-primary'
    // progressBar.style = 'transform: translateX(-100%);'
    // progressWrapper.appendChild(progressBar)

    const uploadList = document.querySelector('#upload_lines')
    uploadList.appendChild(fileUpload)
  }

  process() {
    this.insertUpload();

    this.directUpload.create((error, blob) => {
      if (error) {
        // Handle the error
      } else {
        const uploadData = {
          upload_data: {
            filename: blob.filename,
            byte_size: blob.byte_size,
            checksum: blob.checksum,
            signed_blob_id: blob.signed_id,
          },
        }

        post(this.endpoint, {
          body: JSON.stringify(uploadData),
          contentType: 'application/json',
          responseKind: 'json',
        }).then(() => {
          // Remove the upload element after successful upload
          const uploadElement = document.querySelector(`#upload_${this.directUpload.id}`);
          if (uploadElement) {
            uploadElement.remove();
          }
        }).catch((error) => {
          console.error('Upload failed:', error);
          // Optionally, update the UI to show the error
        });
      }
    })
  }
}

export default class extends Controller {
  static targets = ['fileInput']
  static values = { endpoint: String }

  connect() {
    this.element.addEventListener('dragover', this.dragOver)
    this.element.addEventListener('dragenter', this.dragOver)
    this.element.addEventListener('dragleave', this.dragOut)
    this.element.addEventListener('drop', this.dragOut)
  }

  disconnect() {
    this.element.removeEventListener('dragover', this.dragOver)
    this.element.removeEventListener('dragenter', this.dragOver)
    this.element.removeEventListener('dragleave', this.dragOut)
    this.element.removeEventListener('drop', this.dragOut)
  }

  dragOut(e) {
    e.currentTarget.classList.remove('!border-brand-accent');
    e.preventDefault()
    e.stopPropagation()
  }

  dragOver(e) {
    e.currentTarget.classList.add('!border-brand-accent');
    e.preventDefault()
    e.stopPropagation()
  }

  trigger() {
    console.log('triggering file input')
    this.fileInputTarget.click()
  }

  acceptFiles(event) {
    console.log('accepting files', event)
    event.preventDefault()
    const files = event.dataTransfer
      ? event.dataTransfer.files
      : event.target.files
    ;[...files].forEach((f) => {
      new Upload(f, this.endpointValue).process()
    })
  }
}
