import { Controller } from "@hotwired/stimulus"
import { getDocument, GlobalWorkerOptions, version } from 'pdfjs-dist'
// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry"
// import * as pdfjsLib from 'https://cdn.jsdelivr.net/npm/pdfjs-dist@4.7.76/build/pdf.mjs';
// import { PDFViewer } from 'https://cdn.jsdelivr.net/npm/pdfjs-dist@4.7.76/web/pdf_viewer.mjs';
import * as pdfjsViewer from 'pdfjs-dist/web/pdf_viewer.mjs'

const MAX_CANVAS_PIXELS = 0;
const TEXT_LAYER_MODE = 1;

// Connects to data-controller="pdf-reader"
export default class extends Controller {
  // static targets = ["pageNumber", "viewerContainer"]
  static targets = ["viewerContainer"]
  static values = { url: String }

  currentPage = 1
  // this.currentPage
  pdf = null
  pdfViewer = null
  pdfDocument = null
  resizeTimeout = null

  connect() {
    console.log('connected pdf reader')
    GlobalWorkerOptions.workerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist@${version}/build/pdf.worker.min.mjs`
    this.currentPage = 1

    this.createPdfViewer()
    
    // Setup resize observer
    // this.resizeObserver = new ResizeObserver(() => {
    //   console.log('resize observer')
    //   // this.pdfViewer.currentScaleValue = 'page-fit'
    //   this.resizeToFitContainer()
    // })
    // this.resizeObserver.observe(this.viewerContainerTarget)

    // window.addEventListener('resize', () => {
    //   // this.pdfViewer.currentScaleValue = 'page-fit'
    //   this.resizeToFitContainer()
    // });
    window.addEventListener('resize', () => {
      // Debounce resize events
      // clearTimeout(resizeTimeout);
      // resizeTimeout = setTimeout(scaleToFit, 100);
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.resizeToFitContainer()
      }, 100);
    });

    document.documentElement.style.setProperty('--page-border', '0px solid transparent')
  }

  disconnect() {
    console.log('disconnected pdf reader')
    this.pdf?.destroy()
    // remove all pages
    this.viewerContainerTarget.innerHTML = ''
    this.resizeObserver?.disconnect()
  }

  createPdfViewer() {
    const eventBus = new pdfjsViewer.EventBus();

    const linkService = new pdfjsViewer.PDFLinkService({
      eventBus,
    });

    const l10n = new pdfjsViewer.GenericL10n();

    const container = this.viewerContainerTarget

    // clear container and set the following dom element
    // <div id="viewer" class="pdfViewer"></div>
    container.innerHTML = ''
    const viewer = document.createElement('div')
    viewer.id = 'viewer'
    viewer.classList.add('pdfViewer')
    container.appendChild(viewer)

    this.pdfViewer = new pdfjsViewer.PDFViewer({
      container,
      eventBus,
      linkService,
      l10n: l10n,
      renderer: 'canvas',
      useOnlyCssZoom: true,
      // Increase max canvas size (e.g., for high-DPI displays)
      // maxCanvasPixels: 16777216 * 4, // 4 times default (32M)
      maxCanvasPixels: MAX_CANVAS_PIXELS,
      textLayerMode: TEXT_LAYER_MODE,
      removePageBorders: true,
    });

    eventBus.on("pagesinit", () => {
      // We can use pdfViewer now, e.g. let's change default scale.
      // his.pdfViewer.currentScaleValue = "page-width";
      this.pdfViewer.currentScaleValue = "page-fit";
    });

    eventBus.on('scalechanging', function(evt) {
      console.log('Scale changing to:', evt.scale);
    });

    getDocument(this.urlValue).promise.then((pdfDocument) => {
      // pdfViewer.setDocument(pdfDocument);
      this.pdfDocument?.destroy()
      this.pdfDocument = pdfDocument;
      console.log(pdfDocument)
      this.pdfViewer.setDocument(this.pdfDocument);
    });
  }

  resizeToFitContainer() {
    if (!this.pdfViewer) return
    // const container = this.viewerContainerTarget
    // const containerWidth = container.clientWidth
    // const currentPage = this.pdfViewer.getPageView(0)
    // if (!currentPage) return
    // Calculate the scale factor needed to fit the container
    // const currentPage = this.pdfViewer.getPageView(0)
    // if (!currentPage) return
    // console.log(currentPage.canvas.width, currentPage.canvas.height)
    // console.log(currentPage.pdfPage.getViewport({ scale: 1.0 }).width)
    // const scaleFactor = containerWidth / currentPage.pdfPage.getViewport({ scale: 1.0 }).width
    this.pdfViewer.currentScaleValue = "page-fit";
  }

  async loadPdf() {
    const loadingTask = getDocument(this.urlValue)
    const _pdf = await loadingTask.promise
    this.pdf?.destroy()
    this.pdf = _pdf
    console.log(this.pdf)
    // this.pageNumberTarget.value = this.currentPage
    this.renderAllPages()
  }

  async renderAllPages() {
    for (let i = 1; i <= this.pdf.numPages; i++) {
      this.currentPage = i
      await this.renderPage(i)
    }
  }

  async renderPage(pageNumber) {
    const page = await this.pdf.getPage(pageNumber)
    // const viewport = page.getViewport({ scale: 1.75 })
    const viewport = page.getViewport({ scale: 1.0 })

    let pageContainer = this.viewerContainerTarget.querySelector(`[data-page-number="${pageNumber}"]`)
    let canvas

    if (pageContainer) {
      canvas = pageContainer.querySelector('canvas')
    } else {
      pageContainer = document.createElement("div")
      pageContainer.classList.add("page-container", "flex", "justify-center", "max-w-[1200px]", "mx-auto")
      pageContainer.dataset.pageNumber = pageNumber
      this.viewerContainerTarget.appendChild(pageContainer)

      canvas = document.createElement("canvas")
      canvas.classList.add("w-full")
      pageContainer.appendChild(canvas)
    }

    canvas.width = viewport.width
    canvas.height = viewport.height

    const context = canvas.getContext("2d")
    const renderContext = {
      canvasContext: context,
      viewport: viewport
    }
    await page.render(renderContext)
  }

  // prevPage() {
  //   if (this.currentPage > 1) {
  //     this.currentPage -= 1
  //     this.renderPage()
  //     this.pageNumberTarget.value = this.currentPage
  //   }
  // }
  //
  // nextPage() {
  //   if (this.currentPage < this.pdf.numPages) {
  //     this.currentPage += 1
  //     this.renderPage()
  //     this.pageNumberTarget.value = this.currentPage
  //   }
  // }
  //
  // changePage() {
  //   let requestedPage = Number(this.pageNumberTarget.value)
  //   if (requestedPage > 0 && requestedPage <= this.pdf.numPages) {
  //     this.currentPage = requestedPage
  //     this.renderPage()
  //   } else {
  //     alert(`Invalid page number (Max: ${this.pdf.numPages})`)
  //     this.pageNumberTarget.value = this.currentPage
  //   }
  // }
}
