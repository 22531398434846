import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
  }

  static targets = [
    "instructionContainer",
    "template"
  ]

  connect() {
  }

  disconnect() {
  }

  add(event) {
    console.log('add', this.instructionContainerTarget, this.templateTarget)
    console.log('add', this.templateTarget.innerHTML)
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
    this.instructionContainerTarget.insertAdjacentHTML('beforeend', content);
    event.preventDefault();
    return false;
  }
}
