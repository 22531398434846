import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    appendLoaderTo: String,
    appendLoaderTemplateSelector: String,
  }

  connect() {
    this.connectClearableInputs()
  }

  connectClearableInputs() {
    this.element.querySelectorAll(".clearable-input").forEach(input => {
      const clearButton = input.nextElementSibling

      // on blur, submit form if field is blank
      input.addEventListener("blur", () => {
        if (input.value === "") {
          input.form.requestSubmit()
        }
      }
      )
    })
  }

  appendLoader() {
    if (this.appendLoaderTemplateSelectorValue) {
      const template = document.querySelector(this.appendLoaderTemplateSelectorValue)
      const loader = template.content.cloneNode(true)

      const target = document.querySelector(this.appendLoaderToValue)
      target.appendChild(loader)
      console.log("appended loader")
    }
  }

  onSubmit() {
    this.appendLoader()

    this.submitButtons().forEach(button => {
      button.disabled = true
    })
  }

  submitButtons() {
    return this.element.querySelectorAll("input[type='submit']")
  }

  destroyNested(e) {
    e.preventDefault()
    const wrapper = e.target.closest(".nested-wrapper")
    // hide and the mark _destroy field as true
    wrapper.style.display = "none"
    wrapper.querySelector("input[name*='_destroy']").value = 1
  }

  removeNested(e) {
    e.preventDefault()
    const wrapper = e.target.closest(".nested-wrapper")
    wrapper.remove()
  }
}
