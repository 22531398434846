// Import and register all your controllers from the importmap under controllers/*
import { application } from "./application"

// Eager load all controllers defined in the import map under controllers/**/*_controller
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
// eagerLoadControllersFrom("controllers", application)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)

// import DropdownController from "./dropdown_controller"
// application.register("dropdown", DropdownController)

import LeaveAfterController from "./leave_after_controller"
application.register("leave-after", LeaveAfterController)

import FormController from "./form_controller"
application.register("form", FormController)

import BotStageInstructionsController from "./bot_stage_instructions_controller"
application.register("bot-stage-instructions", BotStageInstructionsController)

import BotStageInstructionController from "./bot_stage_instruction_controller"
application.register("bot-stage-instruction", BotStageInstructionController)

import HasModalController from "./has_modal_controller"
application.register("has-modal", HasModalController)

import BotObjectivesController from "./bot_objectives_controller"
application.register("bot-objectives", BotObjectivesController)

import BotObjectiveController from "./bot_objective_controller"
application.register("bot-objective", BotObjectiveController)

import BotContactRolesController from "./bot_contact_roles_controller"
application.register("bot-contact-roles", BotContactRolesController)

import BotContactRoleController from "./bot_contact_role_controller"
application.register("bot-contact-role", BotContactRoleController)

import UploadzoneController from "./uploadzone_controller"
application.register("uploadzone", UploadzoneController)

import PdfViewerController from "./pdf_viewer_controller"
application.register("pdf-viewer", PdfViewerController)

import RemoveableController from "./removeable_controller"
application.register("removeable", RemoveableController)

import TaskTypesController from "./task_types_controller"
application.register("task-types", TaskTypesController)

import RecaptchaController from "./recaptcha_controller"
application.register("recaptcha", RecaptchaController)
