import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "selectionArea"]

  connect() {
    this.toggle()
  }

  toggle() {
    if (this.checkboxTarget.checked) {
      this.selectionAreaTarget.classList.remove("hidden")
    } else {
      this.selectionAreaTarget.classList.add("hidden")
    }
  }
}
