import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // trigger for the modal trigger
  static targets = ["trigger", "close"]

  connect() {
  }

  showModal(event) {
    console.log('show modal')
    this.element.querySelector(".modal")?.showModal()
    event.preventDefault()
  }

  closeModal(event) {
    console.log('close modal')
    this.element.querySelector(".modal")?.close()
    event.preventDefault()
  }
}
